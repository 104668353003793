// 子节点列表表头
export const columns = () => {
  return [
    {
      title: '序号',
      width: 60,
      scopedSlots: { customRender: '_index' },
      align: 'center'
    },
    {
      title: '名称',
      align: 'center',
      dataIndex: 'fullName'
    },
    {
      title: '所属部门',
      align: 'center',
      dataIndex: 'departmentName',
      key: 'departmentName'
    },
    {
      title: '标签',
      align: 'center',
      dataIndex: 'tag'
    },
    {
      title: '所在位置',
      align: 'center',
      dataIndex: 'location'
    },
    // {
    //   title: '操作',
    //   align: 'center',
    //   key: 'action',
    //   scopedSlots: { customRender: 'action' }
    // }
  ]
}

// 标签页
export const tabListNoTitle = function () {
  return [
    {
      key: 'basicsInfo',
      tab: '基础信息'
    },
    {
      key: 'metricsConfigList',
      tab: '指标配置'
    },
  ]
}
// 监测点类型
export const selectOptions = function () {
  return [
    {
      name: '设备/设施',
      value: 'DEVICE_FACILITY'
    },
    {
      name: '其他',
      value: 'OTHER'
    }
  ]
}
// 监测点标会类型
export const plotType = function () {
  return [
    {
      name: '点',
      value: 'marker'
    },
    {
      name: '线',
      value: 'polyline'
    },
    {
      name: '面',
      value: 'polygon'
    }
  ]
}
