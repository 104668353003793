<template>
  <a-form :form="form">
    <a-form-item label="名称"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-input v-decorator="['name', {rules: [{ required: true, message: '分类名称不能为空' }]}]" placeholder="请输入"></a-input>
    </a-form-item>
    <a-form-item label="部门" :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-tree-select
        v-decorator="['orgId', {rules: [{ required: true, message: '所属部门不能为空' }]}]"
        :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
        :treeData="orgTree"
        allowClear
        placeholder="请选择所属部门"
      ></a-tree-select>
    </a-form-item>
    <a-form-item label="类型"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-select v-decorator="['type', {initialValue:'DEVICE_FACILITY'}]">
        <a-select-option v-for="item in selectOptions" :value="item.value" :key="item.value">
          {{item.name}}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="上级节点"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-input-search
        placeholder="请选择监测点"
        v-decorator="['pName', { rules: [{ required: true, message: '请选择或填写名称' }] }]"
        @search="clickMonitoringPoint">
        <a-button type="primary" slot="enterButton">
          <a-icon type="plus"/>
        </a-button>
      </a-input-search>
    </a-form-item>
    <a-form-item label="标签"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-input v-decorator="['tag']" placeholder="请输入"></a-input>
    </a-form-item>
    <a-form-item label="标会类型"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-select :disabled="disabled" v-decorator="['plotType', {initialValue:'marker'}]">
        <a-select-option v-for="item in plotType" :value="item.value" :key="item.value">
          {{item.name}}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="节点排序"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-input-number :min="1" v-decorator="['sorter']" placeholder="请输入"></a-input-number>
    </a-form-item>
    <a-form-item label="是否统计"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-radio-group v-decorator="['statistics',{initialValue:false}]">
        <a-radio :value="true">
          是
        </a-radio>
        <a-radio :value="false">
          否
        </a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label="是否监测"
                 :label-col="questionLabelCol"
                 :wrapper-col="questionWrapperCol">
      <a-radio-group v-decorator="['monitor',{initialValue:false}]">
        <a-radio :value="true">
          是
        </a-radio>
        <a-radio :value="false">
          否
        </a-radio>
      </a-radio-group>
    </a-form-item>
    <a-modal
      :footer="null"
      :destroyOnClose="true"
      title="监测点"
      :visible="visible"
      @cancel="handCancel"
    >
      <monitoringPointTree :excludeId="entityId" @selectedTreeNode="selectedTreeNode" @selectTreeNodeDetail="selectTreeNodeDetail"></monitoringPointTree>
    </a-modal>
  </a-form>
</template>

<script>
  import { typeFormVO } from './common/typeFormVO'
  import SERVICE_URLS from '@/api/service.url'
  import entityCURDCallback from '../../../common/mixins/entityCURDCallback'
  import monitoringPointTree from '@/components/monitoringPointTree/List'

  export default {
    mixins: [entityCURDCallback],
    components: { monitoringPointTree },
    props: {
      treeData: {
        type: Array,
        required: true
      },
      sup_this: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        default: 'edit'
      }
    },
    name: 'typeForm',
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.monitoringPoint,
        initFormItem: typeFormVO(),
        showFormItem: false,
        questionLabelCol: { span: 6 },
        questionWrapperCol: { span: 17 },
        parentItem: null,
        selectOptions: [
          {
            name: '设备/设施',
            value: 'DEVICE_FACILITY'
          },
          {
            name: '其他',
            value: 'OTHER'
          }
        ],
        plotType: [
          {
            name: '点',
            value: 'marker'
          },
          {
            name: '线',
            value: 'polyline'
          },
          {
            name: '面',
            value: 'polygon'
          }
        ],
        disabled: false,
        visible: false,
        orgTree: []
      }
    },
    created () {
      this.getOrgTree()
    },
    methods: {
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      loadData (id) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.detail_root,
          params: { id },
          noTips: true,
          success: (data) => {
            this.entityId = id
            if (typeof this.setFields !== 'undefined' && this.setFields instanceof Function) {
              this.setFields(data.body)
            } else {
              console.error('Form子组件内部未定义setFields方法')
            }
          }
        })
      },
      setVOFields (values) {
        Object.assign(this.formItem, values)
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.queryParentNode(values.pid)
        this.form.setFieldsValue({
          name: this.formItem.name,
          sorter: this.formItem.sorter,
          type: this.formItem.type,
          tag: this.formItem.tag,
          plotType: this.formItem.plotType,
          orgId: this.formItem.orgId,
          statistics: this.formItem.statistics,
          monitor: this.formItem.monitor
        })
      },
      clickMonitoringPoint () {
        this.visible = true
      },
      handCancel () {
        this.visible = false
      },
      selectedTreeNode (key, node) {
        this.formItem.pid = node.dataRef.key
        this.form.setFieldsValue({
          pName: node.dataRef.title
        })
        this.visible = false
      },
      selectTreeNodeDetail (detail) {

      },
      queryParentNode (id) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.detail_root,
          params: { id },
          noTips: true,
          success: (data) => {
            this.form.setFieldsValue({
              pName: data.body.name
            })
          }
        })
      }
    }
  }
</script>

<style scoped>
  .dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all .3s;
  }

  .dynamic-delete-button:hover {
    color: #777;
  }

  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
</style>