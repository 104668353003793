<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <!--      <a-form-item label="指标编码" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">-->
      <!--        <a-input-->
      <!--          v-decorator="['code', { rules: [{ required: true, message: '请输入指标编码' }] }]"-->
      <!--          placeholder="请输入指标编码"-->
      <!--        ></a-input>-->
      <!--      </a-form-item>-->
      <a-form-item label="指标类型" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-select
          placeholder="请选择指标类型"
          v-decorator="['metricsType', { initialValue: 'yc', rules: [{ required: true, message: '请选择指标类型' }] }]"
          :allowClear="true"
          @select="selectMetricsType"
        >
          <a-select-option v-for="item in metricsType" :key="item.value" :value="item.value">{{
            item.name
            }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所属网关" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input-search
          v-model="gatewayName"
          placeholder="请选择网关"
          @search="handleGateway">
          <a-button type="primary" slot="enterButton">
            <a-icon type="plus"/>
          </a-button>
        </a-input-search>
      </a-form-item>
      <a-form-item label="变量名" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['enName', { rules: [{ required: true, message: '请输入指标变量名' }] }]"
          placeholder="请输入指标变量名"
        ></a-input>
      </a-form-item>
      <a-form-item label="变量别名" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['enNameAlias', { rules: [{ required: true, message: '请输入指标变量别名' }] }]"
          placeholder="请输入指标变量别名"
        ></a-input>
      </a-form-item>
      <a-form-item label="描述" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['cnName', { rules: [{ required: true, message: '请输入指标描述' }] }]"
          placeholder="请输入指标描述"
        ></a-input>
      </a-form-item>
      <a-form-item label="组态别名" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['cnNameAlias', { rules: [{ required: true, message: '请输入指标描述别名' }] }]"
          placeholder="请输入指标描述别名"
        ></a-input>
      </a-form-item>
      <a-form-item label="标签" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['tag', { rules: [{ required: true, message: '请输入指标标签' }] }]"
          placeholder="请输入指标标签"
        ></a-input>
      </a-form-item>
      <template v-if="selectMetricsTypeValue === 'yc' || selectMetricsTypeValue === 'yt'">
        <a-form-item label="指标区间" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
          <a-input style="width: 155px;" v-decorator="['minValue']" placeholder="请输入最小值"></a-input>
          ~
          <a-input style="width: 160px;" v-decorator="['maxValue']" placeholder="请输入最大值"></a-input>
        </a-form-item>
        <a-form-item label="指标默认值" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
          <a-input v-decorator="['collectValue',{initialValue:0}]" placeholder="请输入"></a-input>
        </a-form-item>
      </template>
      <a-form-item v-if="selectMetricsTypeValue === 'yc' || selectMetricsTypeValue === 'yt'" label="指标单位" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input v-decorator="['unit']" placeholder="请输入"></a-input>
      </a-form-item>
      <a-form-item
        label="转义"
        :label-col="questionLabelCol"
        :wrapper-col="questionWrapperCol"
        v-if="selectMetricsTypeValue === 'yx' || selectMetricsTypeValue === 'yk'"
      >
        0:&nbsp;&nbsp;<a-input style="width: 135px;" v-decorator="['zero']" placeholder="请输入"></a-input>
        &nbsp;&nbsp;&nbsp; 1:&nbsp;&nbsp;<a-input
        style="width: 135px;"
        v-decorator="['one']"
        placeholder="请输入"
      ></a-input>
      </a-form-item>
      <a-form-item label="地图是否显示" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-radio-group v-decorator="['core',{initialValue:false}]">
          <a-radio :value="true">
            是
          </a-radio>
          <a-radio :value="false">
            否
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="是否监测预警" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-radio-group v-decorator="['monitor',{initialValue:true}]">
          <a-radio :value="true">
            是
          </a-radio>
          <a-radio :value="false">
            否
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="排序" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input-number :min="1" v-decorator="['sorter']"/>
      </a-form-item>
    </a-form>
    <a-modal title="指标网关列表" :width="1200" :footer="null" :visible="gatewayModal" @cancel="closeModal">
      <Gateway @gatewaySelect="gatewaySelect"></Gateway>
    </a-modal>
  </a-spin>
</template>

<script>
  import { configVO } from './common/configVO'
  import { metricsType } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import entityCURDCallback from '@/views/common/mixins/entityCURDCallback'
  import Gateway from '../../../equipment2/equipmentInfo/onLine/operation/config/Gateway'

  export default {
    name: 'Form',
    components: { Gateway },
    mixins: [entityCURDCallback],
    props: {
      typeTree: {
        type: Array
      },
      defaultType: {
        type: [String, Number]
      },
      pointId: {
        type: Number
      }
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.monitoringRealInfo,
        initFormItem: configVO(),
        showFormItem: false,
        metricsType: metricsType(),
        questionLabelCol: { span: 5 },
        questionWrapperCol: { span: 17 },
        coreList: [
          { value: true, label: '是' },
          { value: false, label: '否' }
        ],
        value: true,
        yxList: [
          { value: '0', label: '开' },
          { value: '1', label: '关' }
        ],
        ycList: [
          { value: '0', label: '正常' },
          { value: '1', label: '异常' }
        ],
        escape: null,
        selectMetricsTypeValue: 'yc',
        treeData: [],
        gatewayModal: false,
        gatewayName: '',
        gatewayId: ''
      }
    },
    watch: {
      pointId (val) {
        this.pointId = val
      }
    },
    created () {
    },
    methods: {
      handleGateway () {
        this.gatewayModal = true
      },
      closeModal () {
        this.gatewayModal = false
      },
      gatewaySelect (data) {
        this.gatewayId = data.id
        this.formItem.gatewayId = data.id
        this.gatewayName = data.cnName
        this.gatewayModal = false
      },
      // 把form取到的值 赋给创建的formItem
      setVOFields (values) {
        if (values.metricsType === 'yx' || values.metricsType === 'yk') {
          this.escape = { zero: values.zero, one: values.one }
        }
        this.formItem.pointId = this.pointId + ''
        this.formItem.escapeContent = this.escape
        Object.assign(this.formItem, values)
      },
      // 给form赋值
      setFields (values) {
        Object.assign(this.formItem, values)
        this.gatewayName = this.formItem.gateway.cnName
        this.formItem.gatewayId = this.formItem.gateway.id
        this.form.setFieldsValue({
          cnName: values.cnName,
          enName: values.enName,
          metricsType: values.metricsType,
          sorter: values.sorter,
          core: values.core,
          monitor: values.monitor,
          collectValue: values.collectValue,
          cnNameAlias: values.cnNameAlias,
          tag: values.tag,
          enNameAlias: values.enNameAlias,
          unit: values.unit
        })
        this.selectMetricsTypeValue = values.metricsType
        if (this.selectMetricsTypeValue === 'yc' || this.selectMetricsTypeValue === 'yt') {
          this.form.setFieldsValue({
            maxValue: values.maxValue,
            minValue: values.minValue
          })
        }
        if (this.selectMetricsTypeValue === 'yx' || this.selectMetricsTypeValue === 'yk') {
          this.$nextTick(() => {
            this.form.setFieldsValue({
              zero: JSON.parse(values.escapeContent).zero,
              one: JSON.parse(values.escapeContent).one
            })
          })
        }
      },
      selectMetricsType (value) {
        this.selectMetricsTypeValue = value
        if (value === 'yc') {
          this.form.resetFields({ zero: '', one: '' })
        } else if (value === 'yx') {
          this.form.resetFields({
            maxValue: '', minValue: '', unit: ''
          })
        } else {
          this.form.resetFields({ maxValue: '', minValue: '', unit: '', zero: '', one: '' })
        }
      },
      addSuccessCustomCallback () {
        this.escape = null
        this.selectMetricsTypeValue = 'yc'
      }
    }
  }
</script>

<style scoped></style>
