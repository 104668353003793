export const configVO = () => {
  return {
    id: '',
    pointId: '',
    cnName: '',
    code: '',
    core: true,
    enName: '',
    gatewayId: '',
    escapeContent: '',
    maxValue: '',
    minValue: '',
    metricsType: '',
    unit: '',
    sorter: '',
    new: true,
    orgId: 0,
    cnNameAlias: '',
    tag: '',
    enNameAlias: ''
  }
}
export const typeFormVO = () => {
  return {
    id: '',
    name: '',
    parentId: null,
    sorter: ''
  }
}
