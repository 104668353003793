export const formVO = function () {
  return {
      id: '',
      areaId: undefined,
      name: '',
      location: '',
      memo: '',
      no: '',
      orgId: undefined,
      type: 'DEVICE_FACILITY',
      pid: '',
      plotType: 'marker',
      files: [],
      images: [],
      imageIds: [],
      fileIds: []
  }
}