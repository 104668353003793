export const gatewayColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '厂家类型',
      align: 'center',
      dataIndex: 'type',
      scopedSlots: { customRender: 'typeSlot' },
    },
    {
      title: '网关编号',
      align: 'center',
      dataIndex: 'deviceGatewayNo',
      scopedSlots: { customRender: 'deviceGatewayNo' },
    },
    {
      title: '中文名称',
      align: 'left',
      dataIndex: 'cnName',
    },
    // {
    //   title: '英文名称',
    //   align: 'left',
    //   dataIndex: 'enName',
    // },
    // {
    //   title: '用户名',
    //   align: 'center',
    //   dataIndex: 'username',
    //   customRender: (text, record) => {
    //     const time = `${record.username ? record.username : '-'}` //es6写法
    //     return time
    //   },
    // },
    // {
    //   title: '密码',
    //   align: 'center',
    //   dataIndex: 'password',
    //   customRender: (text, record) => {
    //     const time = `${record.password ? record.password : '-'}` //es6写法
    //     return time
    //   },
    // },
    // {
    //   title: 'URL',
    //   align: 'left',
    //   dataIndex: 'url',
    // },
    {
      title: '安装地点',
      align: 'left',
      dataIndex: 'installLocation',
    },
    {
      title: '操作',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
}

export const gatewayFormVO = () => {
  return {
    title: '',
    cnName: '',
    deviceGatewayNo: '',
    enName: '',
    type: null,
    password: '',
    url: '',
    username: '',
    orgId: 0,
    sim: '',
    powerType: '',
    installTime: '',
    installFirm: '',
    installUser: '',
    installLocation: '',
    collectFrequency: '0',
  }
}

export const companyTypeList = () => {
  return [
    { value: 'jk', label: 'GRM云服务器(巨控)' },
    { value: 'lyyjkj', label: '洛阳愿景科技有限公司' },
    { value: 'tspsdzjs', label: '唐山平升电子技术开发有限公司' },
    { value: 'shktyb', label: '上海肯特仪表股份有限公司' },
  ]
}

// 供电方式
export const powerTypeList = () => {
  return [
    { value: 'BATTERY', label: '电池供电' },
    { value: 'OTHER', label: '其他' },
  ]
}
