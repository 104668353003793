<template>
  <a-spin :spinning="spinning">
    <div class="page-header-index-wide" ref="tree" style="overflow: auto;padding: 10px;" :style="{'max-height': bodyHeight + 'px'}">
      <a-input-search allow-clear style="margin:10px 0" placeholder="输入节点名称" enter-button @change="onChange" @search="onTreeSearch"/>
      <a-tree
        :showIcon="true"
        :autoExpandParent="false"
        @expand="onExpand"
        :expandedKeys="expandedKeys"
        :key="key" v-if="treeShow === true && treeData.length"
        :isLeaf="true" show-line
        :defaultSelectedKeys="[1]" :load-data="onLoadData" :treeData="treeData"
        @select="selectEquipmentTypeTree">
        <a-icon slot="switcherIcon" type="caret-down"/>
        <template slot="custom" slot-scope="item">
          <a-row>
              <span @mouseover="test(item)" @mouseleave="test1">
                <span>{{item.title}}<span style="color: #00A0E9">{{item.num ? '(' + item.num + ')' : ''}}</span></span>
                  <span v-if="item.key === selectEquipmentTypeKey || item.key === mouseoverEquipmentKey" style="width:25%;textAlign:'center'">
                     <span class="button" v-auth="auth_equipment2.MONITORING_POINT_ADD_UPDATE" style="margin-left: 10px;" @click="()=> customHandleAdd(item)"><a-icon type="plus-circle"/></span>
                     <span class="button" v-auth="auth_equipment2.MONITORING_POINT_ADD_UPDATE" style="margin-left: 5px;" v-if="item.key" @click="()=> handleEdit(item.key)"> <a-icon
                       type="edit"/></span>
                     <span class="button" v-auth="auth_equipment2.MONITORING_POINT_DELETE" style="margin-left: 5px;" v-if="item.key" @click="(e)=> deleteTree(item)"><a-icon
                       type="minus-circle"/></span>
                  </span>
              </span>
          </a-row>
        </template>
      </a-tree>
      <a-tree v-if=" treeShow === false && searchTreeData.length" show-line :defaultExpandAll="true" :treeData="searchTreeData" @select="selectEquipmentTypeTree">
        <a-icon slot="switcherIcon" type="caret-down"/>
        <template slot="custom" slot-scope="item">
          <a-row>
              <span @mouseover="test(item)" @mouseleave="test1">
                <span>{{item.title}}<span style="color: #00A0E9">{{item.num ? '(' + item.num + ')' : ''}}</span></span>
                  <span v-if="item.key === selectEquipmentTypeKey || item.key === mouseoverEquipmentKey" style="width:25%;textAlign:'center'">
                     <span class="button" v-auth="auth_equipment2.MONITORING_POINT_ADD_UPDATE" style="margin-left: 10px;" @click="()=> customHandleAdd(item)"><a-icon type="plus-circle"/></span>
                     <span class="button" v-auth="auth_equipment2.MONITORING_POINT_ADD_UPDATE" style="margin-left: 5px;" v-if="item.key" @click="()=> handleEdit(item.key)"> <a-icon
                       type="edit"/></span>
                     <span class="button" v-auth="auth_equipment2.MONITORING_POINT_DELETE" style="margin-left: 5px;" v-if="item.key" @click="(e)=> deleteTree(item)"><a-icon
                       type="minus-circle"/></span>
                  </span>
              </span>
          </a-row>
        </template>
      </a-tree>
      <a-empty v-if="treeData.length === 0 " style="height: 205px;margin-top: 81px;"/>
      <a-modal
        title="增加"
        @ok="handleAddOk"
        @cancel="closeAddModal"
        :visible="addModal"
        type="add">
        <typeform type="add" ref="addRef" :treeData="treeData" :sup_this="sup_this" @addSuccess="addSuccess"
                  @addError="addError"></typeform>
      </a-modal>
      <a-modal
        title="编辑"
        @ok="handleEditOk"
        @cancel="closeEditModal"
        :visible="editModal"
        type="edit">
        <typeform type="edit" ref="editRef" :treeData="treeData" :sup_this="sup_this" @editSuccess="editSuccess"
                  @editError="editError"></typeform>
      </a-modal>
    </div>
  </a-spin>
</template>
<script>
  import entityCRUD from '@/views/common/mixins/entityCRUD'
  import SERVICE_URLS from '@/api/service.url'
  import typeform from './typeForm'
  import { mapState } from 'vuex'

  export default {
    name: 'equipmentTypeTree',
    mixins: [entityCRUD],
    components: { typeform },
    props: {
      sup_this: {
        type: Object,
        required: true
      },
      type: {
        type: String
      }
    },
    data () {
      return {
        addModal: false,
        editModal: false,
        selectEquipmentTypeKey: '0-0',
        entityBaseUrl: SERVICE_URLS.equipment2.monitoringPoint,
        treeData: [],
        searchTreeData: [],
        initColumns: [],
        mouseoverEquipmentKey: '',
        spinning: true,
        id: 0,
        selectNode: null,
        parentKey: null,
        treeShow: true,
        key: 1,
        dropNode: null,
        dragNode: null,
        expandedKeys: [1],
        parentNode: []
      }
    },
    computed: {
      ...mapState({
        bodyHeight: state => {
          return state.app.bodyHeight - 100
        }
      })
    },
    created () {
      this.search()
    },
    methods: {
      onChange (e) {
        if (e.target.value) {
          this.treeShow = false
          this.onTreeSearch(e.target.value)
        } else {
          this.treeShow = true
          ++this.key
        }
      },
      onTreeSearch (value) {
        if (value) {
          this.$http(this, {
            url: SERVICE_URLS.equipment2.monitoringPoint.search_tree_with_data_auth,
            noLoadingBar: true,
            noTips: true,
            data: { name: value, treeNodeId: 0, needNum: true },
            success: (data) => {
              this.searchTreeData = data.body
            }
          })
        }
      },
      onLoadData (treeNode) {
        this.expandedKeys.push(treeNode.$vnode.key)
        const ele = []
        new Set(this.expandedKeys).forEach((data) => {
          ele.push(data)
        })
        this.expandedKeys = ele
        if (treeNode.$vnode.key === 1) {
          return
        }
        return new Promise(resolve => {
          this.$http(this, {
            url: SERVICE_URLS.equipment2.monitoringPoint.build_tree_with_data_auth,
            noLoadingBar: true,
            noTips: true,
            data: {
              treeNodeId: treeNode.$vnode.key,
              needNum: true,
              levelNum: 2
            },
            success: (data) => {
              treeNode.dataRef.children = data.body
              this.treeData = [...this.treeData]
            }
          })
          resolve()
        })
      },
      customHandleAdd (item) {
        this.addModal = true
        this.$nextTick(function () {
          this.$refs.addRef.form.setFieldsValue({
            pName: item.title
          })
          this.$refs.addRef.formItem.pid = item.key
        })
      },
      search () {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.build_tree_with_data_auth,
          noTips: true,
          data: {
            treeNodeId: this.id,
            needNum: true,
            levelNum: 2
          },
          success: (data) => {
            this.treeData = []
            this.treeData = data.body
            this.spinning = false
            this.$emit('defaultNodeKey', this.treeData[0].key)
          }
        })
      },
      deleteTree (item) {
        const _this = this
        this.$confirm({
          title: '确认删除操作?',
          content: '此数据将会被删除',
          okText: '删除',
          okType: 'danger',
          cancelText: '取消',
          onOk () {
            _this.$http(_this, {
              url: SERVICE_URLS.equipment2.monitoringPoint.delete,
              noTips: true,
              params: { id: item.key },
              success: (data) => {
                // 删除树节点
                _this.onLoadData(_this.selectNode.vcTreeNode)
                _this.sup_this.statistics()
              }
            })
          },
          onCancel () {
          }
        })
      },
      selectEquipmentTypeTree (selectedKeys, e) {
        this.selectNode = e.node
        if (selectedKeys.length > 0) {
          this.sup_this.restoreDefault()
          this.selectEquipmentTypeKey = selectedKeys[0]
          this.sup_this.searchParams.id = selectedKeys[0]
          this.sup_this.searchParams.selectType = ''
          this.sup_this.selectTreeId = selectedKeys[0]
          this.sup_this.searchParams.level = e.node.dataRef.level
          this.sup_this.getDetail()
        }
      },
      handleEdit (id) {
        this.editModal = true
        this.$nextTick(() => {
          this.$refs.editRef.loadData(id)
        })
      },
      addSuccess (data) {
        this.onLoadData(this.selectNode)
        this.addModal = false
        this.sup_this.statistics()
      },
      editSuccess () {
        this.editModal = false
        this.onLoadData(this.selectNode.vcTreeNode)
        this.$emit('monitoringPointEditSuccess')
      },
      test (item) {
        this.mouseoverEquipmentKey = item.key
      },
      test1 () {
        this.mouseoverEquipmentKey = ''
      },
      delTreeNodeById (treeData, id) {
        treeData.forEach((item, index, arr) => {
          if (item.key === id) {
            arr.splice(index, 1)
          } else {
            if (item.children && item.children.length > 0) {
              this.delTreeNodeById(item.children, id)
            }
          }
        })
      },
      onDrop (info) {
        this.dropNode = info.node //目标节点
        this.dragNode = info.dragNode // 拖拽节点
        this.queryNodeData(this.dropNode.eventKey, this.dragNode.eventKey)

        // this.onLoadData()
      },
      onDragEnter (info) {
      },
      dropNodeEdit (dragKey, formItem) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.update,
          data: formItem,
          params: {
            id: dragKey
          },
          success: (data) => {
            // this.onLoadData(this.dropNode)
            // this.onLoadData(this.dragNode)
          },
          error: (e) => {
            this.$emit('editError', 'httpError')
            this.editErrorCallback(e)
          }
        })
      },
      queryNodeData (dropKey, dragKey) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.detail_root,
          params: { id: dragKey },
          noTips: true,
          success: (data) => {
            const formItem = {}
            Object.assign(formItem, data.body)
            formItem.pid = dropKey
            this.dropNodeEdit(dragKey, formItem)
          }
        })
      },
      onExpand (expandedKeys, e) {
        if (e.expanded) {
          if (e.node.dataRef.children.length > 0 || e.node.dataRef.key === 1) {
            this.expandedKeys.push(e.node.$vnode.key)
            const ele = []
            new Set(this.expandedKeys).forEach((data) => {
              ele.push(data)
            })
            this.expandedKeys = ele
          } else {
            this.onLoadData(e.node)
          }
        } else {
          this.expandedKeys.splice(this.isExpandedKey(e.node.$vnode.key), this.expandedKeys.length - this.isExpandedKey(e.node.$vnode.key))
        }

      },
      isExpandedKey (expandedKes) {
        for (let i = 0; i <= this.expandedKeys.length; i++) {
          if (expandedKes === this.expandedKeys[i]) {
            return i
          }
        }
      }
    }
  }
</script>
<style scoped>
  .ant-tree-title {
    width: 100%;
  }

  .title {
    float: left;
  }
</style>